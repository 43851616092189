import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import BaseIcon from "src/components/Base/BaseIcon"
import DropdownMenu from "src/components/Form/DropdownMenu"

class FilterDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      value: this.props.initialValue || "All",
    }
    this.onSelect = this.onSelect.bind(this)
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true)
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this)

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        isOpen: false,
      })
    }
  }

  onSelect(value) {
    this.setState({ isOpen: false, value: value })
    this.props.onChange(this.props.name, value)
  }

  render() {
    const { name, options } = this.props

    function renderValue(value) {
      let result = value
      const split = value.split(" – ")
      if (split.length === 2) {
        result = (
          <>
            {split[0]} <small>{split[1]}</small>
          </>
        )
      }
      return result
    }

    return (
      <Wrapper
        className={`
          ${this.props.className ? this.props.className : ""}
          ${this.state.isOpen ? " -open" : ""}
          ${this.state.value !== "All" ? " -active" : ""}
        `}
        onClick={() => {
          this.setState({ isOpen: !this.state.isOpen })
        }}
      >
        <Label>{name}:</Label>
        <Value>{renderValue(this.state.value)}</Value>
        <Icon name="chevron-down" source="feather" />
        <StyledDropdownMenu
          name={name.toLowerCase()}
          items={options}
          currentValue={this.state.value}
          visible={this.state.isOpen}
          showAll={!this.props.initialValue}
          onSelect={this.onSelect}
        />
      </Wrapper>
    )
  }
}

export default FilterDropdown

const Wrapper = styled.div`
  height: 50px;
  margin-bottom: 10px;
  padding: 0 20px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 25px;
  line-height: 50px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:nth-child(n + 2) {
    margin-left: 10px;
  }

  &:hover,
  &.-open {
    border-color: #cfdbdb;
  }

  &.-active {
    border-color: ${(p) => p.theme.color.primary};
  }
`

const Label = styled.span``

const Value = styled.span`
  margin-left: 15px;
  font-weight: 600;
  color: #000;

  small {
    margin-left: 7px;
    position: relative;
    top: -1px;
    font-weight: normal;
    font-size: 13px;
    color: #b4b3b8;
  }
`

const Icon = styled(BaseIcon)`
  width: 20px;
  height: 20px;
  margin-left: 5px;
  color: #46cad8;
`

const StyledDropdownMenu = styled(DropdownMenu)`
  left: 0;
  transform-origin: 50% 0;
  transform: scaleY(0.9);

  &.-visible {
    transform: none;
  }
`
