import React from "react"
import styled from "styled-components"
import FilterDropdown from "src/components/Form/FilterDropdown"

const TeamFilters = ({ filters, onChange }) => {
  const state = {}

  function onFiltersChange(name, value) {
    state[name] = value === "All" ? "" : value
    onChange(state)
  }

  return (
    <Wrapper>
      <Items>
        {filters.map(({ name, options }, index) => (
          <FilterDropdown
            name={name}
            options={options}
            key={index}
            onChange={onFiltersChange}
          />
        ))}
      </Items>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* margin-bottom: 30px; */
  padding-left: 20px;
  padding-right: 20px;
`

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default TeamFilters
