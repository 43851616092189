import React, { useState } from "react"
import styled from "styled-components"
import { animateScroll } from "react-scroll"
import { media } from "src/styles/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseIcon from "src/components/Base/BaseIcon"
import TeamListDetails from "./TeamListDetails"
import { get } from "lodash"

function filterByOrganisationName(filters, key, item) {
    if (filters[key]) {
        if (filters[key] === "Anima International") {
            return filters[key] === item.organisation
        } else {
            return filters[key].includes(item.organisationOriginal)
        }
    } else {
        return true
    }
}

function applyOrgFilters(items, key, filters) {
    return items.filter(item => {
        if (key === "Organisation") {
            return filterByOrganisationName(filters, key, item);
        }
        let value = item[key.toLowerCase()] || ""
        value = typeof value === "string" ? [value] : value
        return value.includes(filters[key])
    })
}

function applyGroupFilters(members, group, groups) {
    // Group = "funderaisers", "compaigners", "leadership", etc.
    let items = members.filter(item =>
        group.members.includes(item.name)
    )
    const names = groups.filter(g => g.name === group.name)[0]
        .members
    if (names.length > 0) {
        const itemsArr = []
        names.forEach(name => {
            itemsArr.push(members.filter(m => m.name === name)[0])
        })
        items = itemsArr
    }
    return items;
}

const TeamList = ({ groups, members, filters, filtersEmpty, onRef }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(-1)
  const [activeItemId, setActiveItemId] = useState(-1)
  const [prevActiveItemId, setPrevActiveItemId] = useState(-2)
  const activeItem = members.find(i => i.id === activeItemId)

  const detailsData =
    typeof activeItemId === "string"
      ? {
          firstName: activeItem.name.split(" ")[0],
          organisation: activeItem.organisation,
          organisationOriginal: activeItem.organisationOriginal,
          responsibilities: activeItem.responsibilities,
          country: activeItem.country,
          language: activeItem.language,
          email: activeItem.email,
          bio: activeItem.bio,
        }
      : {}

  function onItemClick(id, index, e) {
    setActiveItemIndex(index === activeItemIndex ? -1 : index)
    setActiveItemId(id === activeItemId ? -1 : id)
    setPrevActiveItemId(activeItemId)
    if (activeItemId === -1 && e) {
      const scrollTop = window.scrollY + 250
      animateScroll.scrollTo(scrollTop, {
        duration: 700,
        smooth: "easeInOutQuart",
      })
    }
  }

  function clearDetails() {
    onItemClick(-1, -1)
  }

  onRef(clearDetails) // expose clearDetails for parent component

  return <Wrapper>
    <Inner>
      <GroupsWrapper
        groupsCount={groups.length}
        id="team-list-groups-wrapper"
        style={{
          transform:
            "translateX(-100vw)" /* inline styling helps avoid an animation bug */,
        }}
      >
        {["", ...groups].map((group, index) => {
          let items
          if (group === "" && filtersEmpty) {
            items = []
          } else if (group === "") {
            items = members
            Object.keys(filters).forEach(key => {
              if (filters[key]) {
                items = applyOrgFilters(items, key, filters);
                items.sort((a, b) => a.name.localeCompare(b.name))
              }
            })
          } else {
              items = applyGroupFilters(members, group, groups);
          }
          return <Group key={index}>
              <BaseContainer>
                <Items>
                  {items.map(
                    (
                      {
                        id,
                        name,
                        title,
                        bio,
                        email,
                        organisation,
                        organisationOriginal,
                        country,
                        picture,
                      },
                      itemIndex
                    ) => {
                      const displayName =
                        name === "ZZ" ? "Anonymous Investigator" : name
                      const displayTitle = <>
                          {title},{" "}
                          {organisation === "Anima International" ? organisation : <Country>{country || ""}</Country>}
                        </>
                      return <Item
                          order={itemIndex}
                          className={id === activeItemId ? "-active" : ""}
                          key={id}
                          onClick={handleMemberClick}
                        >
                          <ImageWrap>
                            {get(
                              picture,
                              "localFile.childImageSharp.gatsbyImageData"
                            ) && <Image
                                image={get(
                                  picture,
                                  "localFile.childImageSharp.gatsbyImageData"
                                )}
                                alt={name}
                              />}
                          </ImageWrap>
                          <Name>{displayName}</Name>
                          <TitleWrap>
                            <Title>{displayTitle}</Title>
                            <ClickIndicator>
                              <ClickIndicatorIcon
                                name="chevron-down"
                                source="feather"
                              />
                            </ClickIndicator>
                          </TitleWrap>
                        </Item>

                        function handleMemberClick(e) {
                            return onItemClick(id, itemIndex, e);
                        }
                    }
                  )}
                  {group === "" && items.length === 0 && <NoResults>No results</NoResults>}
                  <TeamListDetails
                    data={detailsData}
                    activeItemIndex={activeItemIndex}
                    activeItemId={activeItemId}
                    prevActiveItemId={prevActiveItemId}
                  />
                </Items>
              </BaseContainer>
            </Group>
        })}
      </GroupsWrapper>
    </Inner>
  </Wrapper>
}

const itemWidth = 208

const Wrapper = styled.div`
  margin-bottom: 70px;
  position: relative;

  &::before {
    content: "";
    height: 2000px;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: linear-gradient(#f6f8f9, #fff);
    border-top: 1px solid #e5e6e8;
  }

  ${media.tablet} {
    margin-bottom: 130px;
  }
`

const Inner = styled.div`
  overflow: hidden;
`

const GroupsWrapper = styled.div`
  display: flex;
  width: ${p => p.groupsCount + 1}00vw;
`

const Group = styled.div`
  width: 100vw;
  padding: 83px 0 25px;
  position: relative;

  &:not(:nth-child(2)) {
    height: 0;
  }
`

const Items = styled.ul`
  display: flex;
  flex-wrap: wrap;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (min-width: 665px) {
    max-width: none;
  }

  @media (min-width: 1080px) {
    margin-left: -30px;
    margin-right: -30px;
  }
`

const Item = styled.li`
  order: ${p => p.order};
  width: 50%;
  margin-bottom: 56px;
  cursor: pointer;

  @media (min-width: 455px) {
    width: ${itemWidth}px;

    &:not(:nth-child(2n + 1)) {
      margin-left: calc((100% - ${itemWidth}px * 2) / 1);
    }
  }

  @media (min-width: 665px) {
    &:not(:nth-child(2n + 1)) {
      margin-left: 0;
    }

    &:not(:nth-child(3n + 1)) {
      margin-left: calc((100% - ${itemWidth}px * 3) / 2);
    }
  }

  @media (min-width: 902px) {
    &:not(:nth-child(3n + 1)) {
      margin-left: 0;
    }

    &:not(:nth-child(4n + 1)) {
      margin-left: calc((100% - ${itemWidth}px * 4) / 3);
    }
  }

  @media (min-width: 1080px) {
    &:not(:nth-child(4n + 1)) {
      margin-left: 0;
    }

    &:not(:nth-child(5n + 1)) {
      margin-left: calc((100% - ${itemWidth}px * 5) / 4);
    }
  }
`

const ImageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 112px;
  margin: 0 auto 18px;

  @media (min-width: 455px) {
    width: 162px;
    height: 162px;
  }
`

const Image = styled(GatsbyImage)`
  box-sizing: content-box;
  width: 98px !important;
  height: 98px !important;
  max-height: 98px;
  border: 0 solid #fff;
  transition: 0.15s;

  &,
  img {
    border-radius: 50%;
  }

  ${Item}:hover &,
  ${Item}.-active & {
    border-width: 7px;
    box-shadow: 0 30px 60px rgba(0, 11, 33, 0.12);
  }

  @media (min-width: 455px) {
    width: 148px !important;
    height: 148px !important;
    max-height: none;
  }
`

const Name = styled.h3`
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.53;
  color: #000;
`

const TitleWrap = styled.div`
  position: relative;
  color: ${p => p.theme.color.textLight};
`

const Title = styled.h4`
  font-size: 15px;
  transition: 0.15s;

  ${Item}:hover & {
    opacity: 0;
  }
`

const Country = styled.span`
  display: inline-block;
`

const ClickIndicator = styled.span`
  opacity: 0;
  margin: 0 auto;
  position: absolute;
  top: 4px;
  right: 0;
  left: 0;
  transform: translateY(-10px);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.1em;
  color: #076262;
  transition: 0.15s;

  ${Item}:hover & {
    opacity: 1;
    transform: translateY(0);
  }
`

const ClickIndicatorIcon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  color: #46cad8;

  ${Item}.-active & {
    transform: rotate(180deg);
  }
`

const NoResults = styled.div`
  width: 100%;
  margin-bottom: 60px;
  text-align: center;
  font-size: 15px;
  color: #8c9d9d;
`

export default TeamList
