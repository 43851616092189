import React, { useRef } from "react"
import styled from "styled-components"
import anime from "animejs"
import { media } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"
import DescriptionList from "src/components/DescriptionList"
import ReactMarkdown from "react-markdown"
import { potentialHeight } from "src/utils/helpers"

const TeamListDetails = ({
  data,
  activeItemIndex,
  activeItemId,
  prevActiveItemId,
}) => {
  const {
    firstName,
    organisation,
    organisationOriginal,
    responsibilities,
    country,
    language,
    email,
    bio,
  } = data

  const isVisible = typeof activeItemId === "string"
  let itemsInRow = 2
  const windowWidth = (typeof window !== "undefined" && window.innerWidth) || 0
  if (windowWidth >= 1080) {
    itemsInRow = 5
  } else if (windowWidth >= 902) {
    itemsInRow = 4
  } else if (windowWidth >= 665) {
    itemsInRow = 3
  }
  const order =
    Math.floor(activeItemIndex / itemsInRow) * itemsInRow + (itemsInRow - 1)
  const wrapper = useRef()
  const caret = useRef()
  let height
  const numberInRow = activeItemIndex % itemsInRow

  function animate(animateIn = true, callback) {
    if (!wrapper.current) return
    const inner = wrapper.current.firstChild

    anime({
      targets: wrapper.current,
      height: animateIn ? height : 0,
      marginBottom: animateIn ? 60 : 0,
      duration: animateIn ? 500 : 300,
      easing: "easeInOutQuart",
      complete: () => {
        callback && callback()
        if (animateIn) {
          wrapper.current.style.height = "auto"
        }
      },
    })

    anime({
      targets: inner,
      opacity: animateIn ? 1 : 0,
      duration: animateIn ? 400 : 0,
      delay: animateIn ? 100 : 0,
      easing: "easeInOutQuart",
      begin: () => {
        if (animateIn) {
          inner.style.visibility = "visible"
        }
      },
      complete: () => {
        if (!animateIn) {
          inner.style.visibility = "hidden"
        }
      },
    })

    anime({
      targets: caret.current,
      top: animateIn ? 4 : 15,
      duration: 300,
      easing: "easeInOutQuart",
    })
  }

  setTimeout(() => {
    height = potentialHeight(wrapper.current)

    if (isVisible) {
      if (typeof prevActiveItemId === "string") {
        animate(false, () => {
          wrapper.current.style.order = order
          animate()
        })
      } else {
        wrapper.current.style.order = order
        animate()
      }
    } else {
      animate(false)
    }
  }, 1)

  return (
    <Wrapper ref={wrapper}>
      <Inner>
        <Inner2>
          <Bio>
            <ReactMarkdown children={bio} />
            {email && (
              <Contact>
                <ContactButton href={`mailto:${email}`}>
                  <ContactButtonIcon name="mail" source="feather" />
                  Contact {firstName}
                </ContactButton>
              </Contact>
            )}
          </Bio>
          <Info>
            <InfoColumn>
              <DescriptionList
                title="Organisation"
                details={organisationOriginal || organisation}
              />
              {responsibilities && (
                <DescriptionList
                  title="Responsibilities"
                  details={responsibilities}
                />
              )}
            </InfoColumn>
            <InfoColumn>
              <DescriptionList title="Country" details={country} />
              <DescriptionList title="Language" details={language} />
            </InfoColumn>
          </Info>
        </Inner2>
      </Inner>
      <CaretWrap numberInRow={numberInRow} prevActiveItemId={prevActiveItemId}>
        <Caret ref={caret} />
      </CaretWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  order: -1;
  width: calc(100% + 60px * 2);
  height: 0;
  position: relative;
  background: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background: #fff;
  }

  @media (min-width: 1270px) {
    margin-left: -60px;
    margin-right: -60px;
  }
`

const Inner = styled.div`
  visibility: hidden;
  opacity: 0;
  padding: 35px;

  ${media.tablet} {
    padding: 50px;
  }

  ${media.desktop} {
    padding: 70px;
  }

  ${media.desktopMedium} {
    padding-left: 90px;
    padding-right: 90px;
  }
`

const Inner2 = styled.div`
  position: relative;
  z-index: 1;
  text-align: left;
  font-size: 15px;
  /* line-height: 1.6; */
  color: ${(p) => p.theme.color.textLight};

  @media (min-width: 665px) {
    display: flex;
    justify-content: space-between;
  }
`

const Bio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  strong {
    font-weight 600;
    color: #000;
  }

  @media (min-width: 665px) {
    width: 60%;
    max-width: 470px;
  }

  ${media.desktopMedium} {
    width: 50%;
  }
`

const Info = styled.div`
  margin-top: 50px;
  text-align: center;

  @media (min-width: 665px) {
    width: 30%;
    margin-top: 0;
    text-align: left;
  }

  ${media.desktop} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 35%;
  }

  ${media.desktopMedium} {
    width: 350px;
  }
`

const InfoColumn = styled.div`
  @media (min-width: 665px) {
    &:first-child {
      max-width: 225px;
    }
  }
`

const Contact = styled.div`
  margin-top: 50px;
`

const ContactButton = styled.a`
  display: inline-block;
  height: 50px;
  padding-left: 65px;
  position: relative;
  line-height: 50px;
  font-weight: 500;
  font-size: ${(p) => p.theme.baseFontSize.phone};
  color: #076262;

  &::before {
    content: "";
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(#50d9d6, #3ab6da);
    border-radius: 50%;
  }
`

const ContactButtonIcon = styled(BaseIcon)`
  position: absolute;
  top: 12px;
  left: 14px;
  color: #fff;
`

const CaretWrap = styled.div`
  width: 20px;
  height: 11px;
  overflow: hidden;
  position: absolute;
  top: -8px;
  left: ${(p) => 223 * p.numberInRow + 94}px;
  z-index: 2;
  transition: left
    ${(p) => (typeof p.prevActiveItemId === "string" ? "0.05s 0.45s" : "0")};

  @media (min-width: 1270px) {
    left: ${(p) => 223 * p.numberInRow + 154}px;
  }
`

const Caret = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  top: 15px;
  left: 3px;
  transform: rotate(45deg);
  background: #fff;
  border: solid #4ecdd6;
  border-width: 3px 0 0 3px;
`

export default TeamListDetails
