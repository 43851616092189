import React, { useState } from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"

const TeamGroups = ({ groups, onSelect, onRef }) => {
  const [current, setCurrent] = useState(0)
  onRef(setCurrent) // expose setCurrent for parent component

  return (
    <Wrapper>
      <BaseContainer>
        <List current={current}>
          {groups.map((name, index) => (
            <Item
              className={index === current ? "-active" : ""}
              key={index}
              onClick={() => {
                onSelect(index)
              }}
            >
              {name}
            </Item>
          ))}
        </List>
      </BaseContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  height: 0;
  position: relative;
  transition: 0.25s;

  .-filters-empty & {
    visibility: visible;
    height: 440px;
    opacity: 1;

    @media (min-width: 421px) {
      height: 380px;
    }

    @media (min-width: 496px) {
      height: 320px;
    }

    @media (min-width: 507px) {
      height: 260px;
    }

    @media (min-width: 645px) {
      height: 200px;
    }

    @media (min-width: 991px) {
      height: 140px;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 20px;
  /* color: #bfcbcb; */

  ${media.desktopMedium} {
    /* font-size: 20px; */
  }
`

const Item = styled.li`
  height: 50px;
  margin: 0 5px 10px;
  padding: 0 20px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 25px;
  line-height: 50px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  /* outline: 1px solid red; */
  transition: color 0.15s;

  /*
  &::before,
  &::after {
    content: "";
    visibility: hidden;
    position: absolute;
    left: calc(50% - 7px);
    transform: rotate(45deg);
  }

  &::before {
    width: 14px;
    height: 14px;
    bottom: -49px;
    background: #e5e6e8;
  }

  &::after {
    width: 12px;
    height: 12px;
    margin-left: 1px;
    margin-top: 1px;
    z-index: 1;
    bottom: -48px;
    background: #f6f8f9;
  }

  &:hover {
    color: #000;
  }
  */

  &:hover {
    border-color: #cfdbdb;
  }

  &.-active {
    border-color: ${(p) => p.theme.color.primary};

    /*
    &::before,
    &::after {
      visibility: visible;
    }
    */
  }

  ${media.desktopMedium} {
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
  }
`

export default TeamGroups
