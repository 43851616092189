import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import anime from "animejs"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import { PrimaryTitle } from "src/components/Typography"
import TeamFilters from "src/components/Team/TeamFilters"
import TeamGroups from "src/components/Team/TeamGroups"
import TeamList from "src/components/Team/TeamList"

const filterNames = ["Organisation", "Language"]

export const pageQuery = graphql`
  {
    allStrapiTeamMember {
      nodes {
        strapiId
        Name
        title
        bio
        email
        team_member_responsibilities {
          Name
        }
        organisation {
          name
          original_name
        }
        country {
          name
        }
        languages {
          name
        }
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 148, quality: 85, layout: FIXED)
            }
          }
        }
      }
    }
    allStrapiTeamMemberGroupOrder(sort: { order: ASC, fields: id }) {
      nodes {
        team_member_group {
          Name
        }
        team_members {
          Name
        }
      }
    }
    allStrapiOrganisation {
      nodes {
        country {
          name
        }
        name
        original_name
      }
    }
  }
`

class TeamPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      observed: false,
      filters: {},
      filtersEmpty: true,
      currentGroup: 0,
    }
    this.generateFilters = this.generateFilters.bind(this)
    this.formatOrganisation = this.formatOrganisation.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onGroupSelect = this.onGroupSelect.bind(this)
    this.animateGroups = this.animateGroups.bind(this)
  }

  generateFilters(filterNames, team) {
    const filters = []

    filterNames.forEach((name) => {
      const key = name.toLowerCase()
      const options = []

      team.forEach((item) => {
        const set =
          (typeof item[key] === "string" ? [item[key]] : item[key]) || []
        set.forEach((value) => {
          const val =
            name === "Organisation" ? this.formatOrganisation(value) : value
          if (!options.includes(val)) {
            options.push(val)
          }
        })
      })

      options.sort()

      filters.push({
        name,
        options,
      })
    })

    return filters
  }

  formatOrganisation(name) {
    const organisation =
      this.organisations.find((item) => item.name === name) || {}
    const { country, originalName } = organisation
    const fullName =
      name === "Anima International" ? name : `${country} – ${originalName}`
    return fullName
  }

  onFilterChange(filters) {
    const filterState = {
      ...this.state.filters,
      ...filters,
    }
    const areEmpty = Object.values(filterState).every(
      (v) => v === null || v === ""
    )
    this.setState({
      filters: filterState,
      filtersEmpty: areEmpty,
    })

    this.onGroupSelect(areEmpty ? 0 : -1)
    // this.animateGroups(areEmpty ? this.state.currentGroup : -1)
  }

  onGroupSelect(index) {
    this.setState({
      currentGroup: index,
    })
    this.animateGroups(index)
    this.clearDetails()
    this.setCurrentGroup(index)

    const groupNodes = [...this.groupsWrapper.childNodes]
    groupNodes.forEach((group, groupIndex) => {
      group.style.height = groupIndex === index + 1 ? "auto" : 0
    })
  }

  animateGroups(index) {
    // use this dirty solution instead of props for better performance
    // const groupsWrapper = document.getElementById("team-list-groups-wrapper")
    setTimeout(() => {
      const currentX = this.groupsWrapper.style.transform
      // const groupHeight = this.groupsWrapper.childNodes[index + 1].firstChild
      //   .offsetHeight
      // const height = groupHeight + 250
      anime({
        targets: this.groupsWrapper,
        // height: height,
        translateX: `-${index + 1}00vw`,
        duration: index === -1 || currentX === "none" ? 0 : 700,
        easing: "easeInOutQuart",
        complete: () => {
          if (index === -1) {
            this.groupsWrapper.style.transform = "none"
          }
        },
      })
    })
  }

  componentDidMount() {
    this.setState({
      observed: true,
    })
  }

  render() {
    const {
      data: {
        allStrapiTeamMember,
        allStrapiTeamMemberGroupOrder,
        allStrapiOrganisation,
      },
    } = this.props
    const teamMembers = allStrapiTeamMember.nodes.map((item) => {
      return {
        id: item.strapiId,
        name: item.Name,
        title: item.title,
        bio: item.bio,
        email: item.email,
        responsibilities: item.team_member_responsibilities.map(
          (item) => item.Name
        ),
        organisation: item.organisation && item.organisation.name,
        organisationOriginal:
          item.organisation && item.organisation.original_name,
        country: item.country && item.country.name,
        language: item.languages.map((item) => item.name),
        picture: item.picture,
      }
    })
    const teamGroups = allStrapiTeamMemberGroupOrder.nodes
      .filter((item) => item.team_member_group && item.team_members)
      .map((item) => {
        return {
          name: item.team_member_group.Name,
          members: item.team_members.map((item) => item.Name),
        }
      })
    const organisations = allStrapiOrganisation.nodes.map((item) => {
      return {
        name: item.name,
        originalName: item.original_name,
        country: item.country && item.country.name,
      }
    })
    this.organisations = organisations

    if (typeof document !== "undefined") {
      setTimeout(() => {
        this.groupsWrapper = document.getElementById("team-list-groups-wrapper")
      })
    }

    return (
      <Wrapper
        className={
          (this.state.filtersEmpty ? "-filters-empty" : "-filters-selected") +
          (this.state.observed ? " -observed" : "")
        }
      >
        <Seo
          title="Meet our team"
          description="Our team is a group of specialists who try to change the fate of farm animals every day. Meet our activists from 10 countries. Need help? Contact us!"
        />
        <PageHeader>
          <PrimaryTitle as="h1">Our team</PrimaryTitle>
          <TeamFilters
            filters={this.generateFilters(filterNames, teamMembers)}
            onChange={this.onFilterChange}
          />
        </PageHeader>
        <TeamGroups
          groups={teamGroups.map((item) => item.name)}
          onSelect={this.onGroupSelect}
          filtersSelected={!this.state.filtersEmpty}
          onRef={(ref) => (this.setCurrentGroup = ref)}
        />
        <TeamList
          groups={teamGroups}
          members={teamMembers}
          filters={this.state.filters}
          filtersEmpty={this.state.filtersEmpty}
          onRef={(ref) => (this.clearDetails = ref)}
        />
      </Wrapper>
    )
  }
}

export default TeamPage

const Wrapper = styled.div`
  > :nth-child(2),
  > :nth-child(3) {
    transition: 0.6s 0.3s ${(p) => p.theme.easing.easeOutQuart};
  }

  > :nth-child(3) {
    transition-delay: 0.45s;
  }

  &:not(.-observed) {
    > :nth-child(2),
    > :nth-child(3) {
      opacity: 0;
      transform: translateY(20px);
    }
  }
`

const PageHeader = styled.header`
  padding: 180px 0 50px;
  text-align: center;

  > * {
    transition: 0.6s ${(p) => p.theme.easing.easeOutQuart};

    div${Wrapper}:not(.-observed) & {
      opacity: 0;
      transform: translateY(20px);
    }
  }

  > :nth-child(2) {
    transition-delay: 0.15s;
  }

  ${media.tablet} {
    padding: 250px 0 70px;
  }
`
